import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Dropdown, AdminWebIconWhite, CloseWhiteIcoSvg } from "../../global";
import CONST from "../../locale.json";
import { api } from "../../api"
import { CALL_NOTIFY } from "../../global/store/action";
import { TableView } from './TableView';
import { SELECTEDPARENT, SELECTEDLEAF } from "../store/action";
import "./AssignProject.scss";

const DropdownStyle = {
    border: `1px solid ${CONST.COLOR.BORDERCOLOR}`,
    ":hover": {
        border: `1px solid ${CONST.COLOR.BORDERCOLOR}`,
    },
    ":active": {
        border: `1px solid ${CONST.COLOR.BORDERCOLOR}`,
    },
    fontWeight: 500,
};

const DropdownIndicatorStyle = {
    color: CONST.COLOR.HEADERCOLOR,
    ":hover": {
        color: CONST.COLOR.HEADERCOLOR,
    }
}

const placeholderStyle = {
    color: CONST.COLOR.BORDERBLACK,
};
const header = [{
    "id": "id",
    "name": "Project",
    "appType": "App type",
    "users": "Users"
}];

export const AssignUser = () => {
    const [showProjectTable, setShowProjectTable] = useState<boolean>(true);
    const [projectList, setProjectList] = useState([]);
    const [selectedProjects, setSelectedProjects] = useState<any>([]);
    const [users, setUsers] = useState([]);
    const [userList, setUserlist] = useState([]);
    const [list, setList] = useState<any>([]);
    const projectNameRef = useRef<HTMLInputElement>(null);
    const [flag, setFlag] = useState<boolean>(false);
    const [changesMade, setChangesMade] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(()=>{
        console.log("Changes Made : ",changesMade);
        console.log("List state : ",list)
    },[changesMade,list])

    useEffect(() => {
        dispatch({ type: SELECTEDPARENT, payload: 'Project management' })
        dispatch({ type: SELECTEDLEAF, payload: 'ASSIGNUSER' })
    }, [])
    const fetchUsers = async () => {
        const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHUSER, {}, dispatch)
        if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;

        // filter non admin users
        const respRole = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHROLE, {}, dispatch)
        if (respRole.err) return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (respRole.err || respRole.msg) } });

        let adminRoleId: string = ''
        for (var itr of respRole.data) {
            if (itr.name === "admin") {
                adminRoleId = itr.id
            }
        }

        resp.data = resp.data.data.filter((user: any) => { return user.roleID !== adminRoleId });

        setUsers(resp.data)
        var usrList: any = []
        for (var itr of resp.data) {
            usrList.push({ id: itr.id, label: itr.userName, value: itr.userName })
        }
        // update state variable
        setUserlist(usrList)
    }
    const fetchProjects = async () => {
        // fetch user api'
        let params = 'all';
        const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHPROJECT, { params: params }, dispatch)
        const respUserProject = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHUSERPROJECT, { params: "project" }, dispatch)
        let userProjectMap: Record<string, boolean> = {}
        for (var itr of respUserProject.data) {
            userProjectMap[itr.user_projectID] = itr.users
        }

        for (var itr of resp.data.data) {
            if (userProjectMap[itr.id]) {
                itr.users = userProjectMap[itr.id]
            }
            else {
                itr.users = 0
            }
        }

        if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
        resp.data.data.forEach((item: any) => {
            for (let key in item) {
                if (!(Object.keys(header[0]).includes(key))) {
                    delete item[key]
                }
            }
        })
        setProjectList(resp.data.data)
    }

    const editAssignUser = (id: string, name: string) => {
        setShowProjectTable(false);
        setSelectedProjects({ "id": id, "name": name });
        fetchAssignedUsers(id)
    }
    const fetchAssignedUsers = async (prjId: string) => {

        // fetch projects for userId
        const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHASSIGNPROJECT, { query: { projectID: prjId } }, dispatch)
        console.log("resp:",resp)
        if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
        // if user is not present in user project table
        if (typeof resp.data === "undefined") {
            setFlag(true)
        }
        let assignedUsr: any = [];
        resp.data[prjId].forEach((usr: any) => {
            users.forEach((userDtl: any) => {
                if (usr.userID === userDtl.id) {
                    assignedUsr.push({ id: userDtl.id, label: userDtl.userName, value: userDtl.userName })
                }
            }
            )
        });
        setList(assignedUsr);
    }

    const addToList = (e: any) => {
        // if selected user already assigned and present in assigned list and selected user map then do not add to list 
        let checkduplicate: boolean = false;
        list.forEach((itr: any) => {
            if (itr.id === e.id) {
                return checkduplicate = true;
            }
        })
        if (checkduplicate) {
            return
        }
        // add the selected project to assigned list
        setList((prev: any) => [...prev, { id: e.id, label: e.value.toString(), value: e.value.toString() }])
        setChangesMade(true);
    }
    const removeFromList = (e: any) => {
        // remove the project from assigned list
        setList((state: any) => state.filter((item: any) => item !== e))
        setChangesMade(true);
    }
    const assignUser = async () => {
        // assigned list contain id, label, value ; push only ids into userProject  
        var projectUsers: any = []

        for (var itr of list) {
            projectUsers.push(itr.id)
        }
        if (flag == true) {
            let projectUser = {
                projectID: selectedProjects.id,
                usertIDs: projectUsers
            }
            const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.ASSIGNPROJECT, { params: "project", body: projectUser }, dispatch)
            if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
            setShowProjectTable(true);
            setList([])
            dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "Project has been successfully updated" } });
        } else {
            let userProject = {
                userIDs: projectUsers
            }
            const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.UPDATEASSIGNPROJECT, { params: `project/${selectedProjects.id}`, body: userProject }, dispatch)
            if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
            setShowProjectTable(true);
            dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "Project has been successfully updated" } });
        }
        await fetchUsers()
        await fetchProjects()
    }
    useEffect(() => {
        setList([])
        //Fetch projects list
        fetchProjects();
        // fetch all users
        fetchUsers()
    }, [showProjectTable])
    return (<>
        {
            showProjectTable ? <> <TableView Title={"Assign/unassign users"} tableData={projectList} showSLNo={true} header={Object.values(header[0])} filter="name" onClickEdit={(e) => editAssignUser(e.id, e.name)} /></> :
                // null
                <div className="assign-project-container">
                    <h3 className="dds-h5-lm">Assign/unassign users</h3>
                    <div className="assign-project dds-link">
                        <label>Project name</label>
                        <div className="assign-prject-content">
                            <input className="admin-textbox" ref={projectNameRef} value={selectedProjects.name} type="text" readOnly />
                        </div>
                    </div>

                    <div className="assign-project">
                        <label>Assign users</label>
                        <div className="assign-prject-content">
                            <Dropdown className="--headerproj"
                                dropdownIndicatorStyle={DropdownIndicatorStyle}
                                controlStyle={DropdownStyle} placeholderStyle={placeholderStyle}
                                showValue={false} onchange={addToList}
                                placeholder={"Search users"}
                                isSearchable options={userList} />
                            <div className="assign-new-prj-list">{list.map((element: any) =>
                                <div className="Prj-Label dds-link-sm">
                                    <img src={AdminWebIconWhite}></img>
                                    <span title={element.value.toString()}>
                                        {element.value.toString()}
                                    </span>
                                    <img style={{ width: "10%" }} src={CloseWhiteIcoSvg} onClick={() => removeFromList(element)}>
                                    </img>
                                </div>
                            )}</div>
                        </div>
                    </div>

                    <div className="create-user-btn">
                        <Button title="Back" className="--savebtn" style={{ backgroundColor: 'white', color: 'green' }} onClick={() => setShowProjectTable(true)} disabled={false} />
                        <Button title="Update" className="--savebtn" onClick={assignUser} disabled={!changesMade} />
                    </div>
                </div>
        }
    </>
    )
}